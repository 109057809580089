import React from 'react';
import './style.scss';
import circles from '../../../../assets/img/svg/circles.svg';
import screen1 from '../../../../assets/img/first/screen1.png';
import screen2 from '../../../../assets/img/first/screen2.png';
import ballon1 from '../../../../assets/img/first/ballon1.png';
import ballon2 from '../../../../assets/img/first/ballon2.png';
import ballon3 from '../../../../assets/img/first/ballon3.png';
const ScreensFloat = () => {
  const rand = () => {
    return Math.floor(Math.random() * 51) + 50;
  };

  return (
    <div className="orbit">
      <img src={circles} alt="circles" className="circles" />
      <figure className="screens">
        <picture style={{ '--rand': rand() }}>
          <img src={screen2} alt="tela Inicial ikeawe" />
        </picture>
        <picture style={{ '--rand': rand() }}>
          <img src={screen1} alt="bandeirolas no likeawe" />
        </picture>
      </figure>
      <aside>
        <picture
          style={{ '--rand': rand(), '--delay': '2.5s' }}
          className="up-anim"
        >
          <img src={ballon1} alt="balão networking" />
        </picture>
        <picture
          style={{ '--rand': rand(), '--delay': '2s' }}
          className="up-anim"
        >
          <img src={ballon2} alt="balão compras" />
        </picture>
        <picture
          style={{ '--rand': rand(), '--delay': '3s' }}
          className="up-anim"
        >
          <img src={ballon3} alt="balão socializar" />
        </picture>
      </aside>
    </div>
  );
};

export default ScreensFloat;
