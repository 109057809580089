import React from 'react';
import './style.scss';
import google from '../../../../assets/img/first/stores/google-play.png';
import apple from '../../../../assets/img/first/stores/app-store.png';
const DownloadButtons = ({isMobile}) => {
  return (
    <footer className={isMobile ? 'mobile' : 'desktop'}>
      <span style={{ '--delay': '.6s' }}>baixe agora mesmo!</span>
      <figure>
        <a
          href="https://play.google.com/store/apps/details?id=br.com.app.likeawe"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src={google}
            style={{ '--delay': '.8s' }}
            alt="baixar likeawe no Google Play"
          />
        </a>
        <a
          href="https://apps.apple.com/br/app/likeawe-conectando-pessoas/id6476578982"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src={apple}
            style={{ '--delay': '1s' }}
            alt="baixar likeawe na App Store"
          />
        </a>
      </figure>
    </footer>
  );
};

export default DownloadButtons;
