import React from 'react';

import './style.scss';
import logo from '../../../assets/img/logo.png';
import google from '../../../assets/img/first/stores/google-play.png';
import apple from '../../../assets/img/first/stores/app-store.png';
import ScreensFloat from './ScreensFloat/ScreensFloat';
import DownloadButtons from './DownloadButtons/DownloadButtons';
const FirstSection = () => {

  return (
    <section id="first" className="visible">
      <article>
        <img src={logo} alt="likeawe" className="up-anim" />
        <div className="divider scalex-anim" />
        <h1 style={{ '--delay': '.1s' }} className="up-anim">
          uma solução completa para descobrir e conectar
        </h1>
        <p style={{ '--delay': '.3s' }} className="up-anim">
          simples, com design intuitivo e uma experiência capaz de trazer uma
          nova tecnologia para realidade de todos.
        </p>
        <DownloadButtons isMobile={false} />
        
      </article>
      <aside>
        <ScreensFloat />
      </aside>
        <DownloadButtons isMobile={true} />
    </section>
  );
};

export default FirstSection;
