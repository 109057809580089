import React from 'react';
import FirstSection from '../components/sections/First/FirstSection';


const Home = () => {
  return (
    <>
      <main>
        <FirstSection />
      </main>
    </>
  );
};

export default Home;
